import defaultTheme from 'tailwindcss/defaultTheme'
import plugin from 'tailwindcss/plugin'
import UITWPreset from '@oen.web.vue/ui/tailwind'

function variableColor(
  variableName: string,
  defaultColor: string,
  defaultAlpha?: number,
) {
  return ({
    opacityValue,
    opacityVariable,
  }: {
    opacityValue?: string
    opacityVariable?: string
  }): string => {
    if (!opacityVariable && opacityValue) {
      return `rgb(var(--${variableName}, ${defaultColor}) / ${opacityValue})`
    } else if (defaultAlpha) {
      return `rgb(var(--${variableName}, ${defaultColor}) / var(--${variableName}-alpha, ${defaultAlpha}))`
    } else if (opacityValue) {
      return `rgb(var(--${variableName}, ${defaultColor}) / var(--${variableName}-alpha, ${opacityValue}))`
    } else {
      return `rgb(var(--${variableName}, ${defaultColor}) / var(--${variableName}-alpha, 1))`
    }
  }
}

const colors = {
  // Theme
  // Colour palette for component styling
  oen: {
    ui: {
      primary: {
        DEFAULT: variableColor('theme-color-primary', '0 108 224'),
        light: variableColor('theme-color-primary-light', '243 244 246'),
        lighter: variableColor('theme-color-primary-lighter', '221 221 221'),
        lightest: variableColor(
          'theme-color-primary-lightest',
          '221 221 221',
          0.05,
        ),
        text: variableColor('theme-color-primary-text', '0 108 224'),
        button: {
          hover: variableColor(
            'theme-color-primary-button-hover',
            '38 130 229',
          ),
          pressed: variableColor(
            'theme-color-primary-button-pressed',
            '65 147 233',
          ),
          disabled: variableColor(
            'theme-color-primary-button-disabled',
            '0 108 224',
            0.2,
          ),
        },
      },
    },
  },
  dark: {
    DEFAULT: 'rgb(0,17,51)',
    bg: 'rgb(51,65,92)',
  },
  light: 'rgb(255,255,255)',
  // White
  white: {
    DEFAULT: 'rgb(255,255,255)',
    dim: 'rgba(255,255,255,0.15)',
    dimmer: 'rgba(255,255,255,0.05)',
  },
  // Gray
  gray: {
    DEFAULT: 'rgba(92, 102, 123, 1)',
    light: 'rgba(0,17,51,0.15)',
    lighter: 'rgba(0,17,51,0.05)',
    lightest: 'rgba(0,17,51,0.02)',
    bg: 'rgb(250,250,251)',
    'lighter-active': 'rgba(0,17,51,0.10)',
    'light-1_2': 'rgba(0,17,51,0.075)',
    'login-UI': 'rgba(243,244,246,1)',
  },
  // Aux
  red: {
    DEFAULT: 'rgba(237, 95, 116, 1)',
    lighter: 'rgba(255,225,230,1)',
    text: 'rgba(205, 61, 100, 1)',
    errorText: 'rgba(255,51,85)',
  },
  // Orange will inherit all the blue palette
  get orange() {
    return this.blue
  },
  blue: {
    DEFAULT: 'rgba(0,108,224,1)',
    light: 'rgba(243,244,246,1)',
    lighter: 'rgba(218, 232, 245, 0.25)',
    lightest: 'rgba(255, 97, 0, 0.05)',
    text: 'rgba(0,108,224,1)',
    'button-hover': 'rgb(38,130,229)',
    'button-pressed': 'rgb(65,147,233)',
    'button-focused': 'rgb(0,108,224)',
    'button-disabled': 'rgba(0, 108, 224, 0.2)',
  },
  yellow: {
    DEFAULT: 'rgba(244, 190, 88, 1)',
    lighter: 'rgba(255,187,0,0.15)',
    text: 'rgb(178,96,0)',
  },
  green: {
    DEFAULT: 'rgba(25, 166, 114, 1)',
    lighter: 'rgba(34,204,102,0.15)',
    text: 'rgba(8, 129, 92, 1)',
    valid: 'rgb(8,135,62)',
  },
  purple: {
    DEFAULT: 'rgba(156, 130, 219, 1)',
    lighter: 'rgba(153,102,255,0.15)',
    text: 'rgba(130, 95, 195, 1)',
  },
  pink: {
    DEFAULT: 'rgb(255,68,187)',
    lighter: 'rgba(255,68,187,0.15)',
    text: 'rgb(196,47,142)',
  },
  // Text
  primary: {
    text: variableColor('theme-color-text-primary', '51 51 51'),
    'dark-text': 'rgb(255,255,255)',
  },
  secondary: {
    text: variableColor('theme-color-text-secondary', '92 102 123'),
    'dark-text': 'rgba(255,255,255,0.7)',
  },
  tertiary: {
    text: variableColor('theme-color-text-tertiary', '0 17 51', 0.3),
    'dark-text': 'rgba(255,255,255,0.4)',
  },
  // Remove what is left of tailwind
  teal: {},
  indigo: {},
  transparent: 'transparent',
  error: 'rgb(237, 95, 116)',
  black: 'rgba(51,51,51,1)',
  inherit: 'inherit',
}
/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/**/*.{html,js,ts,vue,mdx}',
    './stories/**/*.{html,js,ts,vue,mdx}',
    './vite.config.ts',
    './node_modules/\\@oen.web.vue/ui/src/**/*.vue',
  ],
  presets: [UITWPreset],
  theme: {
    colors: colors,
    extend: {
      maxWidth: {
        100: '18rem',
      },
      fontFamily: {
        sans: [
          `var(--theme-font-family, Inter)`,
          ...defaultTheme.fontFamily.sans,
        ],
      },
      boxShadow: {
        'ria-1': `0px 3px 15px rgba(0, 17, 51, 0.05)`,
        'ria-2': '0px 10px 40px rgba(0, 17, 51, 0.1)',
        'ria-3': '0px 10px 40px rgba(0, 17, 51, 0.15)',
        'inset-1': 'inset -1px 1px 0 rgba(0, 17, 51, 0.05)',
        'outline-element-focus': `0 0 0 1px ${colors.orange.DEFAULT}`,
        'outline-input-focus': `inset 0 0 0 1px ${colors.orange.DEFAULT}`,
        'outline-input-focus-error': `inset 0 0 0 1px ${colors.red.DEFAULT}`,
        'outline-input-orange': `0 0 0 1px ${colors.orange.DEFAULT}`,
        'outline-input-red': `0 0 0 1px ${colors.red.DEFAULT}`,
        'outline-orange': `0 0 0 2px ${colors.orange.lighter}`,
      },
      lineHeight: {
        header: 'clamp(1.625rem, calc(1rem + 2vw), 2.6rem)',
        subText: '1.1275rem',
      },
      fontSize: {
        header: 'clamp(1.25rem, calc(0.8rem + 2vw), 2rem)',
      },
    },
  },
  plugins: [
    plugin(function ({ addUtilities, config }) {
      const typesets = {
        '.type-h1': {
          fontSize: config('theme.fontSize.2xl'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.normal'),
        },
        '.type-h2': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.normal'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-h3': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
        },
        '.type-number': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.bold'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-subtitle': {
          fontSize: config('theme.fontSize.base'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.tight'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-body': {
          fontSize: '15px',
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-caption': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.5'),
        },
        '.type-caption-xs': {
          fontSize: config('theme.fontSize.xs'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.widest'),
          lineHeight: config('theme.lineHeight.4'),
        },
        '.type-overline': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.widest'),
          lineHeight: config('theme.lineHeight.5'),
          textTransform: 'uppercase',
        },
        '.type-button': {
          fontSize: config('theme.fontSize.base'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-helper': {
          fontSize: config('theme.fontSize.xs'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.4'),
        },
        '.type-intro': {
          fontSize: config('theme.fontSize.xl'),
          fontWeight: config('theme.fontWeight.semibold'),
          lineHeight: config('theme.lineHeight.relaxed'),
          color: config('theme.colors.black'),
        },
        '.type-header': {
          fontSize: config('theme.fontSize.header'),
          fontWeight: config('theme.fontWeight.semibold'),
          lineHeight: config('theme.lineHeight.header'),
          letterSpacing: config('theme.letterSpacing.tightest'),
        },
        '.type-subText': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.normal'),
          lineHeight: config('theme.lineHeight.subText'),
        },
        '.type-cardHeaderTitle': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.normal'),
        },
      }
      addUtilities(typesets)
    }),
  ],
}
