export const brands = {
  xe: 'xe',
  johnlewis: 'johnlewis',
  britline: 'britline',
} as const

export const brandHostNames = {
  xe: 'xe',
  johnlewis: 'johnlewismoney',
  britline: 'cabips',
} as const

export const brandIds = {
  xe: 8,
  johnlewis: 6,
  britline: 7,
} as const

export const brandDisplayNames = {
  xe: 'Xe',
  britline: 'BIPS',
  johnlewis: 'John Lewis Money International Payments',
}

export const brandSessionCookieNames = {
  xe: 'xe_sso_id_launchpad',
  britline: 'britline_sso_id_launchpad',
  johnlewis: 'johnlewis_sso_id_launchpad',
}
