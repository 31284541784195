import ApiHelper from '~/utils/ApiHelper'
import type { ReferenceSourceType } from '~/types'

interface IConfirmForgotPasswordRequest {
  username: string
  password: string
  code: string
  deviceId: string
  deviceToken: string
  devicePlatform: string
  platform: string
  referenceSource: ReferenceSourceType | ''
  preferredLanguage: string
  redirectUrl: string | null
  codeChallenge: string
  codeChallengeMethod: string
}

export interface IConfirmForgotPasswordResponse {
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }
  sessionId?: string
  customerProfile: {
    id: number
    accountType: string
    unitcode: string
    twoFANumber: {
      number: string
    }
  }
  authCode?: string
  require2FA?: boolean
  twoFactorAuthStatus?: string
}

class ConfirmForgotPassword {
  async exec(payload: IConfirmForgotPasswordRequest): Promise<IConfirmForgotPasswordResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const path = 'authorization/confirmForgotPassword'
    return await apiHelper.post<IConfirmForgotPasswordRequest, IConfirmForgotPasswordResponse>(
      path,
      payload
    )
  }
}

export default new ConfirmForgotPassword()
