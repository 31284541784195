import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { routes, handleHotUpdate } from 'vue-router/auto-routes'
import { setupLayouts } from 'virtual:generated-layouts'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import config from './config/tailwind'
import { useEnvStore } from '~/stores/env'
import prismic from '~/helpers/prismic'
import { setI18n } from './modules/i18n'
import type { UserModule } from './types'

useTailwindConfig(config)

import 'primeicons/primeicons.css'
import './styles/main.css'

import {
  continueAsGuard,
  forgotPasswordGuard,
  homeGuard,
  redirectUriGuard,
  signinGuard,
  signupEmailVerificationGuard,
  twoFactorAuthGuard,
} from './utils/navGuards'

// workaround for nested layouts issue when using vite-plugin-vue-layouts and unplugin-vue-pages
// https://github.com/JohnCampionJr/vite-plugin-vue-layouts/issues/134
const nestedRoute = routes.find((route) => route.path === '/2fa')

if (nestedRoute) {
  nestedRoute.meta = nestedRoute.meta ?? {}
  nestedRoute.meta.layout = false
}

const routesWithLayouts = setupLayouts(routes)

const app = createApp(App)

// install all modules under `modules/`
Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true })).forEach(
  (i) => i.install?.(app)
)

const head = createHead()
app.use(head)

app.use(PrimeVue)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routesWithLayouts,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
})

const envStore = useEnvStore()
envStore.init().then(async () => {
  const i18nObject = await setI18n()
  const { userLocale, i18n } = await i18nObject
  const registerStore = useRegisterStore()
  registerStore.setUserLocale(userLocale)
  /*
    Navigational guards to prevent direct navigation to any part of signup, signin and forgot-password flow
    Guards check store values to determine if user has followed through the flow journey
  */
  router.beforeEach(async (to, from) => {
    //NavStore's redirectedFrom is used to track previous location
    //Currently, useRoute() and path based routing doesn't play nicely
    if (from.name) {
      useNavStore().setRedirectedFrom(from.name.toString())
    }

    //NavGuards need to be called in sequential order
    return (
      (await continueAsGuard(to, from)) ??
      (await twoFactorAuthGuard(to)) ??
      homeGuard(to) ??
      redirectUriGuard(to, from) ??
      forgotPasswordGuard(to) ??
      signinGuard(to, from) ??
      signupEmailVerificationGuard(to)
    )
  })

  app.use(router)
  app.use(prismic)
  app.use(i18n)
  app.mount('#app')
})

// This will update routes at runtime without reloading the page
if (import.meta.hot) {
  handleHotUpdate(router)
}
