import { platforms } from '~/constants/platforms'
import type { ISignInResponse } from '~/api/types'

/**
 * Checks if the new linked account selection is required for this login and sets required data in store
 * @param response response from weblogin
 * @returns true when user signed in to linked account
 */
export const isLinkedAccount = async (response: ISignInResponse) => {
  const registerStore = useRegisterStore()

  // Linked account selection only applies to MT platforms
  if (registerStore.platform !== platforms.XEMT && registerStore.platform !== platforms.BRITLINE) {
    return false
  }

  if (response.linkedAccounts?.length) {
    const filteredAccounts = response.linkedAccounts.filter((x) => x.mobileNumber !== undefined)
    if (filteredAccounts.length === 1) {
      useTwoFactorAuthStore().setTwoFAProfileId(filteredAccounts[0].profileId)
      if (filteredAccounts[0]?.mobileNumber?.mobilePhone) {
        const number =
          filteredAccounts[0].mobileNumber.mobilePrefix +
          filteredAccounts[0].mobileNumber.mobilePhone
        if (await useTwoFactorAuthStore().lookupPhoneNumber(number)) {
          useTwoFactorAuthStore().setPhoneNumber(number)
        }
      }
      return false
    }
    if (filteredAccounts.length >= 1) {
      registerStore.setLinkedAccounts(filteredAccounts)
    }
    if (filteredAccounts.length === 0) {
      registerStore.setLinkedAccounts(response.linkedAccounts)
    }
    return true
  }

  return false
}
