import type { ISignInResponse } from '~/api/types'
import { isLinkedAccount } from '~/helpers/linkedAccount'

/**
 * determine the 2FA page where the user should be redirected to depending on the 2fa auth status stored in 2fa auth store
 * @param response from weblogin
 * @returns string 2fa page path
 */
export async function useTwoFAPageRedirect(response: ISignInResponse, changeNumber = false) {
  const twoFactorAuthStore = useTwoFactorAuthStore()

  if (
    twoFactorAuthStore.twoFactorAuthStatus === 'Locked' ||
    twoFactorAuthStore.twoFactorAuthStatus === 'SetupDisabled'
  ) {
    return '/2fa/locked'
  }

  if (
    !changeNumber &&
    twoFactorAuthStore.twoFactorAuthStatus === 'SetupRequired' &&
    (await isLinkedAccount(response))
  ) {
    return '/2fa/choose-number'
  }

  if (twoFactorAuthStore.phoneNumberIsValid) {
    return changeNumber ? '/2fa/change-number' : '/2fa/phone-validation'
  }

  if (twoFactorAuthStore.phoneNumber.length) {
    return '/2fa/review-number'
  }

  return '/2fa/add-number'
}
