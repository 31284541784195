import ApiHelper from '~/utils/ApiHelper'

export interface IResendCodeResponse {
  destination: string
  deliveryMedium: number
  attributeName: string
}

interface IResendCodeRequest {
  username: string
  preferredLanguage?: string
  location?: string
  platform: string
}
export interface IResendCodeError {
  message: string
  code: string
  requestId: string
  retryable: boolean
  retryDelay: number
}

class CodeConfirmation {
  async exec(payload: IResendCodeRequest, browserId: string): Promise<IResendCodeResponse> {
    const apiHelper = new ApiHelper()
    apiHelper.setHeader('browserId', browserId)
    apiHelper.setHeader('deviceId', 'integration_device_id')
    const path = 'authorization/resendConfirmationCode'

    const result = await apiHelper.post<IResendCodeRequest, IResendCodeResponse>(path, payload)
    return result
  }
}

export default new CodeConfirmation()
