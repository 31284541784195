export const routes = [
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/__w/account-site/account-site/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "withoutStepper"
    }
  },
  {
    path: '/2fa',
    /* internal name: '/2fa' */
    /* no component */
    children: [
      {
        path: 'change-number',
        name: '/2fa/change-number',
        component: () => import('/__w/account-site/account-site/src/pages/2fa/change-number.vue'),
        alias: ["/2fa/add-number","/2fa/review-number"],
        /* no children */
        meta: {
          "twoFactorAuthFlow": true,
          "layout": "twoFactorAuth"
        }
      },
      {
        path: 'choose-number',
        name: '/2fa/choose-number',
        component: () => import('/__w/account-site/account-site/src/pages/2fa/choose-number.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "signinFlow": true,
          "showLeaveRegistrationImg": true
        }
      },
      {
        path: 'locked',
        name: '/2fa/locked',
        component: () => import('/__w/account-site/account-site/src/pages/2fa/locked.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "twoFactorAuthFlow": true,
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'phone-validation',
        name: '/2fa/phone-validation',
        component: () => import('/__w/account-site/account-site/src/pages/2fa/phone-validation.vue'),
        /* no children */
        meta: {
          "twoFactorAuthFlow": true,
          "layout": "twoFactorAuth"
        }
      },
      {
        path: 'verify-number',
        name: '/2fa/verify-number',
        component: () => import('/__w/account-site/account-site/src/pages/2fa/verify-number.vue'),
        /* no children */
        meta: {
          "twoFactorAuthFlow": true,
          "layout": "twoFactorAuth"
        }
      }
    ],
  },
  {
    path: '/account-type',
    name: '/account-type',
    component: () => import('/__w/account-site/account-site/src/pages/account-type.vue'),
    /* no children */
    meta: {
      "layout": "withoutStepper",
      "signinFlow": true,
      "showGoBackButton": false
    }
  },
  {
    path: '/change-password',
    /* internal name: '/change-password' */
    /* no component */
    children: [
      {
        path: '',
        name: '/change-password/',
        component: () => import('/__w/account-site/account-site/src/pages/change-password/index.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showGoBackButton": true
        }
      }
    ],
  },
  {
    path: '/continue',
    name: '/continue',
    component: () => import('/__w/account-site/account-site/src/pages/continue.vue'),
    /* no children */
    meta: {
      "layout": "withoutStepper"
    }
  },
  {
    path: '/forgot-password',
    /* internal name: '/forgot-password' */
    /* no component */
    children: [
      {
        path: '',
        name: '/forgot-password/',
        component: () => import('/__w/account-site/account-site/src/pages/forgot-password/index.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showGoBackButton": true,
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'email-verification',
        name: '/forgot-password/email-verification',
        component: () => import('/__w/account-site/account-site/src/pages/forgot-password/email-verification.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showGoBackButton": true,
          "forgotPasswordFlow": true,
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'error',
        name: '/forgot-password/error',
        component: () => import('/__w/account-site/account-site/src/pages/forgot-password/error.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'new-password',
        name: '/forgot-password/new-password',
        component: () => import('/__w/account-site/account-site/src/pages/forgot-password/new-password.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showGoBackButton": true,
          "forgotPasswordFlow": true,
          "showLeaveRegistrationImg": false
        }
      }
    ],
  },
  {
    path: '/signin',
    /* internal name: '/signin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/signin/',
        component: () => import('/__w/account-site/account-site/src/pages/signin/index.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'account-selection',
        name: '/signin/account-selection',
        component: () => import('/__w/account-site/account-site/src/pages/signin/account-selection.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "signinFlow": true,
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'closed',
        name: '/signin/closed',
        component: () => import('/__w/account-site/account-site/src/pages/signin/closed.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "signinFlow": true,
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'email-verification',
        name: '/signin/email-verification',
        component: () => import('/__w/account-site/account-site/src/pages/signin/email-verification.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'error',
        name: '/signin/error',
        component: () => import('/__w/account-site/account-site/src/pages/signin/error.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "showLeaveRegistrationImg": false
        }
      },
      {
        path: 'locked',
        name: '/signin/locked',
        component: () => import('/__w/account-site/account-site/src/pages/signin/locked.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper",
          "signinFlow": true,
          "showLeaveRegistrationImg": false
        }
      }
    ],
  },
  {
    path: '/signup',
    /* internal name: '/signup' */
    /* no component */
    children: [
      {
        path: '',
        name: '/signup/',
        component: () => import('/__w/account-site/account-site/src/pages/signup/index.vue'),
        /* no children */
        meta: {
          "layout": "default"
        }
      },
      {
        path: 'email-verification',
        name: '/signup/email-verification',
        component: () => import('/__w/account-site/account-site/src/pages/signup/email-verification.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "signupFlow": true
        }
      },
      {
        path: 'error',
        name: '/signup/error',
        component: () => import('/__w/account-site/account-site/src/pages/signup/error.vue'),
        /* no children */
        meta: {
          "layout": "withoutStepper"
        }
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

