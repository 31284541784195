import { platforms } from '~/constants/platforms'
import { navPaths } from '~/constants/navPaths'
import { canParse } from '~/helpers/url'
import type { RouteRecordName } from 'vue-router'

const allowedQueryParamsList = ['_dfxid', '_clientagentid'] as const
type allowedQueryParams = (typeof allowedQueryParamsList)[number]

function stripTrailingSlash(path: string) {
  return path.endsWith('/') ? path.slice(0, -1) : path
}

export function formatProtocol(url: string | null) {
  if (!url) {
    return
  }
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url
  }
  //Defaulting to current window protocol if protocol is missing from redirect url
  return window.location.protocol + '//' + url
}

export function validateRedirectUrl(value?: string) {
  if (!value) {
    return
  }

  if (canParse(value)) {
    return stripTrailingSlash(value.split('?')[0])
  }
}

export function getRedirectQueryParams(value?: string) {
  if (!value || !canParse(value)) {
    return
  }

  const redirectSearchParams = new URL(value).searchParams
  const paramsToKeep = {} as { _dfxid?: string; _clientagentid?: string }
  let hasParams = false

  allowedQueryParamsList.forEach((param) => {
    const paramValue = redirectSearchParams.get(param)
    if (paramValue) {
      paramsToKeep[param] = paramValue
      hasParams = true
    }
  })

  if (!hasParams) {
    return
  }

  const params = Object.keys(paramsToKeep).map((key) => {
    return `${key}=${paramsToKeep[key as allowedQueryParams]}`
  })

  return `${params.join('&')}`
}

export function getRedirectUriByPlatformAndPath(
  platform: string,
  path: RouteRecordName | null | undefined
) {
  const envStore = useEnvStore()
  switch (platform) {
    case platforms.XEMT:
    case platforms.BRITLINE:
    case platforms.JOHN_LEWIS:
      return path === navPaths.SIGNUP
        ? envStore.getEnvVariable('VUE_APP_URL_PERSONAL_REGISTRATION')
        : envStore.getEnvVariable('VUE_APP_URL_TRANSFER')
    case platforms.XERA:
      return envStore.getEnvVariable('VUE_APP_URL_XERA')
    case platforms.XECI:
      return envStore.getEnvVariable('VUE_APP_URL_XECI')
    case platforms.XECD:
      return envStore.getEnvVariable('VUE_APP_URL_XECD')
    case platforms.DEVPORTAL:
      return path === navPaths.SIGNUP
        ? envStore.getEnvVariable('VUE_APP_URL_DEVPORTAL_REGISTRATION')
        : envStore.getEnvVariable('VUE_APP_URL_DEVPORTAL')
    default:
      //if all else fails, go to FXWeb
      return envStore.getEnvVariable('VUE_APP_URL_TRANSFER')
  }
}
