import { parsePhoneNumber } from 'libphonenumber-js'

export const formatPhoneNumber = (phoneNumber: string, redacted = false) => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

  let formattedPhoneNumber = parsedPhoneNumber.formatInternational()

  if (redacted) {
    // return a partially redacted number in the international format
    // e.g. +1 939 555 0013 becomes +1 *** *** 0013

    const redactedNumber = formattedPhoneNumber.replace(/\d(?=(?:\D*\d){4})/g, '*') // redact all but last 4 digits e.g. +* *** *** 0013
    const redactedArr = redactedNumber.split(' ')
    redactedArr[0] = `+${parsedPhoneNumber.countryCallingCode}` //restore country code

    formattedPhoneNumber = redactedArr.join(' ')
  }

  return {
    countryCode: parsedPhoneNumber.country,
    formatInternational: formattedPhoneNumber,
  }
}
