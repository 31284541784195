import { acceptHMRUpdate, defineStore } from 'pinia'
import Send2FA from '~/api/launchpad/authorization/send2FA/post'
import Validate2FA from '~/api/launchpad/authorization/validate2FA/post'
import GetProfile from '~/api/launchpad/profile/get'
import GetLookupPhoneNumber from '~/api/launchpad/twofactorauth/lookupPhoneNumber/get'
import type { TwoFactorAuthChannel } from '~/types'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'

interface TwoFactor {
  channel: TwoFactorAuthChannel | undefined
  required: boolean
  phoneNumber: string
  phoneNumberIsLandline: boolean
  phoneNumberIsValid: boolean
  rememberBrowser: boolean
  sendCodeAttempts: number
  verificationAttempts: number
  useWebLogin: boolean
  twoFactorAuthStatus: string
  twoFAProfileId?: number
  verificationChannels: TwoFactorAuthChannel[]
  lookupPhoneNumberType: string
  previousChannel: TwoFactorAuthChannel | undefined
}

export const useTwoFactorAuthStore = defineStore('twoFactorAuth', () => {
  const twoFactor = reactive<TwoFactor>({
    channel: 'sms',
    required: false,
    phoneNumber: '',
    phoneNumberIsLandline: false,
    phoneNumberIsValid: false,
    rememberBrowser: false,
    sendCodeAttempts: 0,
    verificationAttempts: 0,
    useWebLogin: false,
    twoFactorAuthStatus: '',
    verificationChannels: ['sms', 'call'],
    lookupPhoneNumberType: '',
    previousChannel: '',
  })

  //getters
  const channel = computed(() => twoFactor.channel)
  const previousChannel = computed(() => twoFactor.previousChannel)
  const required = computed(() => twoFactor.required)
  const phoneNumber = computed(() => twoFactor.phoneNumber)
  const parsedPhoneNumber = computed(() =>
    formatPhoneNumber(twoFactor.phoneNumber, twoFactorAuthStatus.value !== 'SetupRequired')
  )
  const phoneNumberIsLandline = computed(() => twoFactor.phoneNumberIsLandline)
  const phoneNumberIsValid = computed(() => twoFactor.phoneNumberIsValid)
  const formattedPhoneNumber = computed(() => {
    if (twoFactor.phoneNumber === '') {
      return ''
    }

    return parsedPhoneNumber.value.formatInternational
  })
  const phoneNumberCountryCode = computed(() => {
    if (twoFactor.phoneNumber === '') {
      return ''
    }
    return parsedPhoneNumber.value.countryCode
  })
  const rememberBrowser = computed(() => twoFactor.rememberBrowser)
  const channelEventText = computed(() => {
    switch (channel.value) {
      case 'sms':
        return 'SMS'
      case 'call':
        return 'Call'
      case 'whatsapp':
        return 'WhatsApp'
      default:
        return ''
    }
  })
  const sendCodeAttempts = computed(() => twoFactor.sendCodeAttempts)
  const verificationAttempts = computed(() => twoFactor.verificationAttempts)
  const useWebLogin = computed(() => twoFactor.useWebLogin)
  const twoFactorAuthStatus = computed(() => twoFactor.twoFactorAuthStatus)
  const verificationChannels = computed(() => twoFactor.verificationChannels)
  const lookupPhoneNumberType = computed(() => twoFactor.lookupPhoneNumberType)

  //Actions
  const setRequired = (required: boolean) => {
    twoFactor.required = required
  }

  const setRememberBrowser = (rememberBrowser: boolean) => {
    twoFactor.rememberBrowser = rememberBrowser
  }

  const setLookupPhoneNumberType = (lookupPhoneNumberType: string) => {
    twoFactor.lookupPhoneNumberType = lookupPhoneNumberType
  }

  const send2FACode = async (channel?: TwoFactorAuthChannel, changePhoneNumber?: boolean) => {
    twoFactor.channel = channel

    if (twoFactor.useWebLogin) {
      const result = await useRegisterStore().signinWith2FA(
        twoFactor.channel,
        twoFactor.phoneNumber,
        undefined,
        undefined,
        changePhoneNumber,
        twoFactor.twoFAProfileId
      )
      return result
    } else {
      const result = await Send2FA.exec({
        channel: twoFactor.channel,
        phoneNumber: twoFactor.phoneNumber,
        changeTwoFANumber: changePhoneNumber,
        location: useNavStore().currentFlow,
        referenceSource: useRegisterStore().referenceSource,
      })
      return result
    }
  }

  const verify2FACode = async (code: string, phoneNumber?: string, changePhoneNumber?: boolean) => {
    if (twoFactor.useWebLogin) {
      const result = await useRegisterStore().signinWith2FA(
        phoneNumber ? 'email' : twoFactor.channel,
        phoneNumber,
        code,
        twoFactor.rememberBrowser,
        changePhoneNumber,
        twoFactor.twoFAProfileId
      )
      return result
    } else {
      const result = await Validate2FA.exec({
        code: code,
        rememberBrowser: twoFactor.rememberBrowser,
        changeTwoFANumber: changePhoneNumber,
        twoFANumber: phoneNumber,
        location: useNavStore().currentFlow,
        channel: twoFactor.channel,
        referenceSource: useRegisterStore().referenceSource,
      })

      return result
    }
  }

  const getProfileData = async () => {
    const registerStore = useRegisterStore()
    const profile = await GetProfile.exec()

    setPhoneNumber(profile.mobilePhone.number)
    await lookupPhoneNumber(profile.mobilePhone.number)
    registerStore.setAccountType(
      profile.profile.accountType === 'Corporate' ? 'Business' : 'Personal'
    )
    registerStore.setEmail(profile.profile.email)
  }

  const setPhoneNumber = (phoneNumber: string) => {
    twoFactor.phoneNumber = phoneNumber
  }
  const lookupPhoneNumber = async (phoneNumber: string) => {
    try {
      const phoneNumberType = await GetLookupPhoneNumber.exec(phoneNumber)
      setPhoneNumberType(phoneNumberType)
    } catch (e) {
      setLookupPhoneNumberType(e?.response?.data?.data?.type)
      twoFactor.phoneNumberIsValid = false
      return false
    }
    return true
  }

  const setPhoneNumberType = (phoneNumberType: string) => {
    twoFactor.phoneNumberIsValid = true
    twoFactor.phoneNumberIsLandline = phoneNumberType === 'Landline'
  }

  const setTwoFactorAuthStatus = async (twoFactorAuthStatus: string) => {
    twoFactor.twoFactorAuthStatus = twoFactorAuthStatus
  }

  const incrementSendCodeAttempts = () => {
    twoFactor.sendCodeAttempts++
    return twoFactor.sendCodeAttempts
  }

  const incrementVerificationAttempts = () => {
    twoFactor.verificationAttempts++
    return twoFactor.verificationAttempts
  }

  const setUseWebLogin = (useWebLogin: boolean) => {
    twoFactor.useWebLogin = useWebLogin
  }

  const setTwoFAProfileId = (profileId: number) => {
    twoFactor.twoFAProfileId = profileId
  }

  const setVerificationChannels = (channels: TwoFactorAuthChannel[]) => {
    if (channels) {
      twoFactor.verificationChannels = channels.map((x) =>
        x.toLowerCase()
      ) as TwoFactorAuthChannel[]
    }
  }

  const setPreviousChannel = (channel?: string) => {
    if (twoFactor.phoneNumberIsLandline) {
      twoFactor.previousChannel = 'call'
      return
    }
    switch (channel) {
      case 'TwoFASMS':
        twoFactor.previousChannel = 'sms'
        break
      case 'TwoFAVoice':
        twoFactor.previousChannel = 'call'
        break
      case 'TwoFAWhatsApp':
        twoFactor.previousChannel = 'whatsapp'
        break
      default:
        twoFactor.previousChannel = 'sms'
        break
    }
  }

  return {
    channel,
    required,
    phoneNumber,
    phoneNumberIsLandline,
    phoneNumberIsValid,
    formattedPhoneNumber,
    phoneNumberCountryCode,
    rememberBrowser,
    channelEventText,
    sendCodeAttempts,
    verificationAttempts,
    useWebLogin,
    twoFactorAuthStatus,
    verificationChannels,
    previousChannel,
    lookupPhoneNumberType,
    setRequired,
    setRememberBrowser,
    send2FACode,
    verify2FACode,
    getProfileData,
    setPhoneNumber,
    lookupPhoneNumber,
    incrementSendCodeAttempts,
    incrementVerificationAttempts,
    setUseWebLogin,
    setTwoFactorAuthStatus,
    setTwoFAProfileId,
    setVerificationChannels,
    setPhoneNumberType,
    setLookupPhoneNumberType,
    setPreviousChannel,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandStore, import.meta.hot))
}
