import type { ReferenceSourceType } from '~/types'
import ApiHelper from '~/utils/ApiHelper'

interface IForgotPasswordResponse {
  destination: string
  deliveryMedium: number
  attributeName: string

  authenticationResult: {
    success: boolean
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
    code?:
      | 'CognitoCodeMismatchException'
      | 'ExpiredCodeException'
      | 'CognitoLimitExceededException'
      | 'CognitoTooManyFailedAttemptsException'
      | 'CognitoTooManyRequestsException'
      | 'CognitoNotAuthorizedException'
  }
  sessionId?: string
}

interface IForgotPasswordRequest {
  username: string
  preferredLanguage?: string
  hcaptcha?: string
  devicePlatform: string
  platform: string
  referenceSource: ReferenceSourceType | ''
}

export interface IForgotPasswordError {
  message: string
  code: string
  statusCode: number
  retryable: boolean
}

class ForgotPassword {
  async exec(payload: IForgotPasswordRequest, deviceId: string): Promise<IForgotPasswordResponse> {
    const apiHelper = new ApiHelper()
    apiHelper.setHeader('deviceId', deviceId)
    const path = 'authorization/forgotPassword'
    return await apiHelper.post<IForgotPasswordRequest, IForgotPasswordResponse>(path, payload)
  }
}
export default new ForgotPassword()
