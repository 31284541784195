<script setup lang="ts">
import ConsentManager from './ConsentManager'
import { useBrandStore } from '~/stores/brand'
import { brands } from '~/constants/brands'

const consentManager = new ConsentManager()
const showCustomize = ref(false)
const showConsentCard = ref(false)
const showConsentCardOverlay = ref(false)
const performanceInputChecked = ref(false)
const marketingInputChecked = ref(false)
const consentCard = ref<HTMLElement | null>(null)
const brandStore = useBrandStore()
const brand = brandStore.brandName
const brandDisplayName = brandStore.brandDisplayName
const systemFieldStore = useSystemFieldStore()

if (window.xeSession && useEnvStore().getEnvVariable('VUE_APP_XE_CONSENT_ENABLED')) {
  window.xeSession.consentManager = consentManager

  consentManager.onLoad(function () {
    //Update analytic's store consent values on load
    const marketingConsent = consentManager.consentState.marketing ?? false
    const performanceConsent = consentManager.consentState.performance ?? false
    useAnalyticsStore().changeConsent({ marketingConsent, performanceConsent })
  })

  consentManager.loadConsentState(window.xeSession.isGeoEurope)

  consentManager.onUpdate(function () {
    //Update analytic's store consent values on update
    const marketingConsent = consentManager.consentState.marketing ?? false
    const performanceConsent = consentManager.consentState.performance ?? false
    useAnalyticsStore().changeConsent({ marketingConsent, performanceConsent })
    useAnalyticsStore().init(false)
    if (brand !== brands.britline) {
      // Send GTM event - 'Consent State Change'
      useAnalyticsStore().gtmTrack({
        event: 'Consent State Change',
        variables: {
          isConsentedToPerformance: window.xeSession.consentManager?.consentState.performance,
          isConsentedToMarketing: window.xeSession.consentManager?.consentState.marketing,
          isConsentedToCompliance: window.xeSession.consentManager?.consentState.compliance,
        },
      })
    }
  })

  if (consentManager.shouldShowConsentPrompt) {
    showConsentCard.value = true
  } else {
    // ensure consent cookie is created
    consentManager.updateConsentState({})
  }
}

const consentCardCustomiseButtonClick = function () {
  if (consentManager.isConsentedToCategory('performance')) {
    performanceInputChecked.value = true
  }

  if (consentManager.isConsentedToCategory('marketing')) {
    marketingInputChecked.value = true
  }

  showCustomize.value = true
  animateModal()
}

const consentCardAcceptButtonClick = function () {
  consentManager.updateConsentState({
    performance: true,
    marketing: true,
  })
  showConsentCard.value = false
}

const categoryBackButtonClick = function () {
  showCustomize.value = false
  animateModal()
}

const consentModalConfirmButtonClick = function () {
  consentManager.updateConsentState({
    performance: performanceInputChecked.value,
    marketing: marketingInputChecked.value,
  })
  showCustomize.value = false
  showConsentCard.value = false
}

function animateModal() {
  const containerRef = consentCard.value

  if (!containerRef) {
    return
  }

  // element size before animation
  const previous = containerRef.getBoundingClientRect()
  window.requestAnimationFrame(() => {
    // element has now resized
    const next = containerRef.getBoundingClientRect()
    containerRef.style.transition = 'height 0.3s'
    containerRef.style.height = `${previous.height}px`
    containerRef.style.opacity = '0'
    window.requestAnimationFrame(() => {
      containerRef.style.height = `${next.height}px`
    })
  })
  containerRef.addEventListener(
    'transitionend',
    () => {
      containerRef.style.height = 'auto'
      containerRef.style.transition = 'opacity 0.3s'
      containerRef.style.opacity = '1'
    },
    { once: true }
  )
}
</script>

<template>
  <Transition appear name="fade">
    <div v-show="showConsentCardOverlay" class="consentCardOverlay">
      <Transition
        appear
        name="slide-left"
        @before-enter="() => (showConsentCardOverlay = true)"
        @leave="() => (showConsentCardOverlay = false)"
      >
        <div v-if="showConsentCard" class="consentCard" aria-modal="true" role="dialog">
          <div ref="consentCard" class="consentManager">
            <template v-if="!showCustomize">
              <h2 class="text-[1.125rem] text-center flex justify-center consentHeader">
                {{ $t('CookieConsent.CookieTitle') }}
                <img class="cookieIcon" src="/src/assets/images/cookie.png" alt="" />
              </h2>
              <hr />
              <p class="!text-[16px]">
                {{ $t('CookieConsent.CookieBody') }}
                <br />
                <a
                  :href="systemFieldStore.cookieNoticeUrl"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {{ $t('CookieConsent.CookiePolicyLink') }} </a
                >.
              </p>
              <div class="consent-button-group mt-4 mb-1">
                <OenUiButton theme="secondary" @click="consentCardCustomiseButtonClick">
                  {{ $t('CookieConsent.CustomizeButton') }}
                </OenUiButton>
                <OenUiButton
                  data-testId="cookieConsent-acceptCookiesButton"
                  @click="consentCardAcceptButtonClick"
                >
                  {{ $t('CookieConsent.AcceptButton') }}
                </OenUiButton>
              </div>
            </template>
            <template v-else>
              <div class="flex">
                <button class="nodecoration-button" @click="categoryBackButtonClick">
                  <ICarbonChevronLeft style="font-size: 1.5rem" />
                </button>
                <h2 class="text-[1.125rem] text-center flex-grow flex justify-center consentHeader">
                  {{ $t('CookieConsent.CustomizeTitle') }}
                </h2>
              </div>
              <hr />

              <div class="consentCategoriesSection">
                <div class="flex mt-4 mb-2">
                  <label class="category-label" for="performance-input">
                    {{ $t('CookieConsent.PerformanceLabel') }}
                  </label>
                  <label class="toggle">
                    <input
                      id="performance-input"
                      v-model="performanceInputChecked"
                      type="checkbox"
                      name="performance-input"
                      :checked="performanceInputChecked"
                    />
                    <span class="input-slider"></span>
                  </label>
                </div>
                <p>
                  {{ $t('CookieConsent.PerformanceDescripton') }}
                </p>

                <div class="flex mt-4 mb-2">
                  <label class="category-label" for="marketing-input">
                    {{ $t('CookieConsent.MarketingLabel') }}
                  </label>
                  <label class="toggle">
                    <input
                      id="marketing-input"
                      v-model="marketingInputChecked"
                      type="checkbox"
                      name="marketing-input"
                      :checked="marketingInputChecked"
                    />
                    <span class="input-slider"></span>
                  </label>
                </div>
                <p>
                  {{ $t('CookieConsent.MarketingDescription') }}
                </p>

                <div class="flex flex-wrap mt-4 mb-2">
                  <p class="category-label">
                    {{ $t('CookieConsent.RequiredLabel') }}
                  </p>
                  <p class="alwaysOn">
                    {{ $t('CookieConsent.AlwaysOn') }}
                  </p>
                </div>
                <p>
                  {{ $t('CookieConsent.RequiredDescription') }}
                </p>
              </div>

              <i18n-t
                keypath="CookieConsent.LearnMoreLinks"
                tag="p"
                class="mt-8 !text-[#333333]"
                scope="global"
              >
                <template #cookie>
                  <a
                    :href="systemFieldStore.cookieNoticeUrl"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {{ $t('CookieConsent.CookiePolicy') }}
                  </a>
                </template>
                <template #privacy>
                  <a
                    :href="systemFieldStore.privacyPolicyUrl"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {{ $t('CookieConsent.PrivacyPolicy') }}
                  </a>
                </template>
                <template #brand>
                  <span> {{ brandDisplayName === 'Xe' ? 'Xe.com' : brandDisplayName }}</span>
                </template>
              </i18n-t>
              <div class="consent-button-group mt-4">
                <OenUiButton @click="consentModalConfirmButtonClick">
                  {{ $t('CookieConsent.DoneButton') }}
                </OenUiButton>
              </div>
            </template>
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<style scoped>
.consentCardOverlay {
  align-items: flex-end;
  background: rgba(119, 119, 119, 0.5);
  height: 100%;
  justify-content: flex-start;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  @screen sm {
    padding-left: 23px;
    padding-bottom: 23px;
  }
}

.consentCard {
  @screen sm {
    max-width: 440px;
  }
  padding: 24px;
  border-radius: 8px;
  animation: 0.5s ease 0s 1 normal none running;
  box-shadow: rgba(35, 55, 80, 0.3) 0px 0px 12px;
  background-color: #fff;
}

.consentHeader {
  font-weight: 500;
}

.cookieIcon {
  height: 1.125rem;
  display: inline-block;
  margin-left: 10px;
  align-self: center;
}

.consentCategoriesSection {
  & .category-label {
    flex-grow: 1;
    color: #333;
    font-weight: 500;
    font-size: 1rem;
  }
  & .alwaysOn {
    color: #054d37;
  }
}

.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

input {
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + .input-slider {
    background-color: #19a672;
  }
}

input:focus + .input-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .input-slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.input-slider {
  border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.input-slider:before {
  border-radius: 50%;
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.consentManager {
  max-height: 80vh;
  overflow: auto;
  transition: opacity 0.3s ease 0s;
  & hr {
    margin: 16px 0px;
    border-width: 1px 0px 0px;
    border-top-style: solid;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-top-color: rgb(221, 221, 221);
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image: initial;
  }

  & .nodecoration-button {
    padding: 0;
    border: none;
    background: none;
    height: 24px;
    align-self: flex-start;
  }
  & .consent-button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  & p {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 17, 51, 0.8);
  }

  & a {
    color: #006ce0;
    text-decoration: none;

    &:visited {
      color: #006ce0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  & .oen-ui-button {
    @screen sm {
      @apply !w-auto;
    }
    @apply !rounded-lg;
  }
}
</style>
