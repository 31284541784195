import type { TwoFactorAuthChannel } from '~/types'
import ApiHelper from '~/utils/ApiHelper'

export interface ISend2FAResponse {
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }
  sessionId?: string
  customerProfile: {
    id: number
    accountType: string
    unitcode: string
    twoFANumber: {
      number: string
    }
  }
  authCode?: string
  require2FA?: boolean
  twoFactorAuthStatus?: string
  verificationChannels: TwoFactorAuthChannel[]
}

interface ISend2FARequest {
  channel: TwoFactorAuthChannel
  phoneNumber: string
  changeTwoFANumber?: boolean
  location: string
}

class SignIn {
  async exec(payload: ISend2FARequest): Promise<ISend2FAResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const result = await apiHelper.post<ISend2FARequest, ISend2FAResponse>(
      'authorization/send2FA',
      payload
    )
    return result
  }
}

export default new SignIn()
