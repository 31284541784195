import type { TwoFactorAuthChannel } from '~/types'
import ApiHelper from '~/utils/ApiHelper'

export interface IValidate2FAResponse {
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }
  sessionId?: string
  customerProfile: {
    id: number
    accountType: string
    unitcode: string
    twoFANumber: {
      number: string
    }
  }
  authCode?: string
  require2FA?: boolean
  twoFactorAuthStatus?: string
}

interface IValidate2FARequest {
  code: string
  rememberBrowser: boolean
  changeTwoFANumber?: boolean
  twoFANumber?: string
  location: string | undefined
  channel?: TwoFactorAuthChannel
  referenceSource: string
}

class SignIn {
  async exec(payload: IValidate2FARequest): Promise<IValidate2FAResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const result = await apiHelper.post<IValidate2FARequest, IValidate2FAResponse>(
      'authorization/validate2FA',
      payload
    )
    return result
  }
}

export default new SignIn()
