import { brands } from '~/constants/brands'
import { mergeWith, isArray } from 'lodash-es'

const commonDirectives = {
  'default-src': ["'none'"],
  'script-src': [
    "'self'",
    'https://www.google.com',
    'https://cdn.segment.com',
    'https://www.gstatic.com',
    'https://cdn.logrocket.io',
    'https://cdn.lr-ingest.io',
    'https://cdn.lr-in.com',
    'https://cdn.lr-in-prod.com',
    'https://cdn.lr-ingest.com',
    'https://cdn.ingest-lr.com',
    'https://cdn.lr-intake.com',
    'https://cdn.intake-lr.com',
    'https://hcaptcha.com',
    'https://*.hcaptcha.com',
  ],
  'style-src': [
    "'self'",
    "'unsafe-inline'",
    'https://fonts.googleapis.com/',
    'https://hcaptcha.com',
    'https://*.hcaptcha.com',
  ],
  'connect-src': [
    "'self'",
    'https://*.xe.com',
    'https://cdn.segment.com',
    'https://api.segment.io',
    'https://*.logrocket.io',
    'https://*.lr-ingest.io',
    'https://*.logrocket.com',
    'https://*.lr-in.com',
    'https://*.lr-in-prod.com',
    'https://*.lr-ingest.com',
    'https://*.ingest-lr.com',
    'https://*.lr-intake.com',
    'https://*.intake-lr.com',
    'https://*.logr-ingest.com',
    'https://hcaptcha.com',
    'https://*.hcaptcha.com',
    'https://rmt4-secure.cdn.prismic.io/',
    'https://images.prismic.io',
  ],
  'img-src': [
    "'self'",
    'data:',
    'https://rmt4-secure.cdn.prismic.io/',
    'https://images.prismic.io',
  ],
  'font-src': ["'self'", 'https://fonts.gstatic.com'],
  'frame-src': ['https://*.google.com', 'https://hcaptcha.com', 'https://*.hcaptcha.com'],
  'worker-src': ["'self'", 'blob:'],
  'child-src': ["'self'", 'blob:'],
}

const XeDirectives = {
  'script-src': [
    'https://cdn-assets-prod.s3.amazonaws.com',
    'https://cdn.amplitude.com/',
    'https://bat.bing.com',
    'https://*.yieldify.com',
    'https://connect.facebook.net',
    'https://d.impactradius-event.com',
    'https://snap.licdn.com',
    'www.googletagmanager.com',
    'https://*.doubleclick.net',
    'https://www.googleadservices.com',
    'https://www.google-analytics.com',
    //hashes of inline scripts loaded via tag manager
    "'sha256-o722/uTfmUvEnVNo1lsC20ON1J9Nv6mh5fhBp/JM5zg='",
    "'sha256-QGhBBYiIM7mw5PeaWX9O0yp5v6Ruq/sLSV0sPgEB0Pc='",
    "'sha256-A498BJacQ5nNaiotTOdoApEc2WNlcLNQS6ETVANALkc='",
    "'sha256-+apHXfN5kBD8uRwBF2KtjPT0POFdY9DSPH59Ihcp3NI='",
    "'sha256-JVNDpCUkT8x6XVr1Fk6jBXBslDKd8PBC67JWa3wGk6I='",
    "'sha256-09SnsvUDEnaauXiaXWLwvroCI5WEVDgXij2cYOFOnH8='",
  ],
  'connect-src': [
    'https://api.amplitude.com/',
    'https://*.analytics.google.com',
    'https://*.doubleclick.net',
    'https://*.oribi.io',
    'https://*.yieldify.com',
    'https://xe-money-transfer.sjv.io',
    'https://*.google-analytics.com',
    'https://*.linkedin.com',
    'https://*.googleapis.com',
    'https://*.yieldify-production.com',
    'https://www.facebook.com',
    'https://bat.bing.com',
  ],
  'img-src': [
    'https://*.linkedin.com',
    'https://*.google.com',
    'https://*.google.co.uk',
    'https://www.google-analytics.com',
    'https://bat.bing.com',
    'https://www.facebook.com/',
    'https://logs-01.loggly.com/',
    'https://www.ojrq.net',
    'https://*.doubleclick.net',
    'https://*.yieldify.com',
  ],
  'font-src': ['https://*.yieldify-production.com'],
  'frame-src': ['https://td.yieldify.com', 'https://www.facebook.com', 'https://*.doubleclick.net'],
}

const BritlineDirectives = {
  'connect-src': ['https://*.cabips.com'],
}

const JohnLewisDirectives = {
  'connect-src': ['https://*.johnlewismoney.com']
}

const mergeArrays = function (a: unknown[], b: unknown[]) {
  if (isArray(a)) {
    return a.concat(b)
  }
}

export function useCSP(brandName: string) {
  let directives

  switch (brandName) {
    case brands.xe:
      directives = mergeWith({}, commonDirectives, XeDirectives, mergeArrays)
      break
    case brands.britline:
      directives = mergeWith({}, commonDirectives, BritlineDirectives, mergeArrays)
      break
      case brands.johnlewis:
      directives = mergeWith({}, commonDirectives, JohnLewisDirectives, mergeArrays)
      break
    default:
      directives = commonDirectives
  }

  directives['connect-src'].push(useEnvStore().getEnvVariable('VUE_APP_URL_TRANSFER'))

  // Ensure launchpad requests are not blocked when running locally
  if (import.meta.hot) {
    directives['connect-src'].push(useEnvStore().getEnvVariable('VUE_APP_ENDPOINTS_LAUNCHPADAPI'))
  }

  return (
    'default-src ' +
    directives['default-src'].join(' ') +
    '; ' +
    'script-src ' +
    directives['script-src'].join(' ') +
    '; ' +
    'style-src ' +
    directives['style-src'].join(' ') +
    '; ' +
    'connect-src ' +
    directives['connect-src'].join(' ') +
    '; ' +
    'img-src ' +
    directives['img-src'].join(' ') +
    '; ' +
    'font-src ' +
    directives['font-src'].join(' ') +
    '; ' +
    'frame-src ' +
    directives['frame-src'].join(' ') +
    '; ' +
    'worker-src ' +
    directives['worker-src'].join(' ') +
    '; ' +
    'child-src ' +
    directives['child-src'].join(' ')
  )
}
