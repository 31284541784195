import { acceptHMRUpdate, defineStore } from 'pinia'
import { useSessionStorage, useLocalStorage } from '@vueuse/core'
import passwordRules from '~/api/launchpad/passwordValidation/get'
import resendConfirmationCode from '~/api/launchpad/authorization/resendConfirmationCode/post'
import resendChangeEmailCodeAPI from '~/api/launchpad/authorization/resendChangeEmailCode/post'
import confirmEmailChangedAPI from '~/api/launchpad/authorization/confirmEmailChanged/post'
import confirmSignup from '~/api/launchpad/authorization/confirmSignup/post'
import signup from '~/api/launchpad/authorization/signup/post'
import signin from '~/api/launchpad/authorization/signin/post'
import forgotPassword from '~/api/launchpad/authorization/forgotPassword/post'
import { formatError } from '~/utils/formatError'
import { logError } from '~/utils/logger'
import confirmForgotPassword from '~/api/launchpad/authorization/confirmPassword/post'
import getGSOTokensApi from '~/api/launchpad/getGSOTokens/get'
import logoutApi from '~/api/launchpad/logout/get'
import userInfoApi from '~/api/launchpad/oauth2/userinfo/get'
import { SEGMENT_EVENTS } from '~/constants/segmentAnalytics'
import { getPlatformByBrand } from '~/utils/brandHelper'
import { platforms } from '~/constants/platforms'
import { DEFAULT_LOCALE } from '~/constants/locales'
import { jwtDecode } from 'jwt-decode'
import { AxiosError } from 'axios'
import type { RemovableRef } from '@vueuse/core'
import type { ValidationRules } from '~/helpers/validatePassword'
import type { AccessToken, ReferenceSourceType, TwoFactorAuthChannel } from '~/types'
import type { IGSOTokensResponse, ILinkedAccount } from '~/api/types'

type Register = {
  email: string
  password: string
  passwordValidationRules: ValidationRules[] | null
  browserId: string
  emailOTPCode: string
  tcVersion: RemovableRef<string>
  devicePlatform: string
  accountType: 'Personal' | 'Business' | 'XECD' | 'XERA' | ''
  deviceId: string
  redirectUrl: RemovableRef<string | null>
  redirectQueryParams?: string
  platform: RemovableRef<string>
  rateLimit: boolean
  tooManyException: boolean
  invalidCode: boolean
  expiredCode: boolean
  referenceSource: ReferenceSourceType | ''
  profileId: number | null
  tokens: IGSOTokensResponse['tokens'] | null
  oAuth: {
    code: string
    state: string
    codeChallenge: string
    codeChallengeMethod: string
  }
  userLocale: string
  helpcentre?: boolean
  linkedAccounts: ILinkedAccount[]
}
export const useRegisterStore = defineStore('register', () => {
  const register = ref<Register>({
    email: '',
    password: '',
    passwordValidationRules: null,
    browserId: 'testbrowserid',
    deviceId: 'integration_device_id',
    emailOTPCode: '',
    tcVersion: useSessionStorage('tcVersion', ''),
    devicePlatform: '',
    helpcentre: undefined,
    accountType: '',
    redirectUrl: useLocalStorage('redirectUrl', null),
    redirectQueryParams: undefined,
    platform: useLocalStorage('platform', ''),
    rateLimit: false,
    tooManyException: false,
    invalidCode: false,
    expiredCode: false,
    referenceSource: '',
    profileId: null,
    tokens: null,
    oAuth: {
      code: '',
      state: '',
      codeChallenge: '',
      codeChallengeMethod: '',
    },
    userLocale: DEFAULT_LOCALE,
    linkedAccounts: [],
  })
  /*************  Getters ******* */
  const email = computed(() => register.value.email)
  const password = computed(() => register.value.password)
  const emailOTPCode = computed(() => register.value.emailOTPCode)
  const passwordValidationRules = computed(() => {
    if (register.value.passwordValidationRules) {
      return register.value.passwordValidationRules
    } else {
      logError('Unable to get validation rules', 'store/register.passwordValidationRules')
    }
    return []
  })

  const rateLimit = computed(() => register.value.rateLimit)
  const tooManyException = computed(() => register.value.tooManyException)
  const invalidCode = computed(() => register.value.invalidCode)
  const expiredCode = computed(() => register.value.expiredCode)
  const accountType = computed(() => register.value.accountType)
  const helpcentre = computed(() => register.value.helpcentre)
  const redirectUrl = computed(() => register.value.redirectUrl)
  const redirectQueryParams = computed(() => register.value.redirectQueryParams)
  const redirectUrlWithParams = computed(() => {
    const redirectParams = redirectQueryParams.value

    if (!redirectParams) {
      return redirectUrl.value
    }

    return `${redirectUrl.value}?${redirectParams}`
  })
  const platform = computed(() => register.value.platform)
  const referenceSource = computed(() => {
    //ReferenceSource can be obtained from Redirect URI or the account type
    if (register.value.referenceSource) {
      return register.value.referenceSource
    }
    //Depends on the account type
    const referenceSource = useSegmentReferenceSource()
    register.value.referenceSource = referenceSource.value ? referenceSource.value : ''
    return register.value.referenceSource
  })

  const segmentVersionType = computed(() => {
    switch (register.value.referenceSource) {
      case SEGMENT_EVENTS.referenceSource.PERSONAL:
        return 'Personal'
      case SEGMENT_EVENTS.referenceSource.BUSINESS:
        return 'Business'
      case SEGMENT_EVENTS.referenceSource.XECD:
        return 'XECD'
      case SEGMENT_EVENTS.referenceSource.XECI:
        return 'XECI'
      case SEGMENT_EVENTS.referenceSource.XERA:
        return 'XERA'
      default:
        return null
    }
  })

  const profileId = computed(() => register.value.profileId)
  const tokens = computed(() => register.value.tokens)
  const authCode = computed(() => register.value.oAuth.code)
  const userLocale = computed(() => register.value.userLocale)
  // Locale is stored as five char,e.g. 'en-GB'
  const preferredLanguage = computed(() => register.value.userLocale.split('-')[0])
  const deviceId = computed(() => register.value.deviceId)
  const linkedAccounts = computed(() => register.value.linkedAccounts)
  //Account type derived purely for segment event
  const segmentAccountType = computed(() =>
    register.value.accountType === 'Business' ? 'Corporate' : 'Consumer'
  )

  /************* Actions  ******* */
  function setEmail(value: string) {
    if (value) {
      register.value.invalidCode = false
      register.value.expiredCode = false
      register.value.rateLimit = false
      register.value.tooManyException = false
      register.value.emailOTPCode = ''
      register.value.email = value
    }
  }

  function setAccountType(value: Register['accountType'] = '') {
    if (value === '') {
      const redirect = register.value.redirectUrl
      if (redirect?.includes('corporate-registration')) {
        value = 'Business'
      } else if (redirect?.includes('personal-registration')) {
        value = 'Personal'
      }
    }
    if (value) {
      register.value.accountType = value
    }
  }

  function setPassword(value: string) {
    register.value.password = value
  }

  function setHelpcentre(value: boolean) {
    register.value.helpcentre = value
  }

  function resetPassword(hcaptcha?: string) {
    return forgotPassword.exec(
      {
        username: register.value.email,
        hcaptcha: hcaptcha,
        devicePlatform: 'web',
        platform: register.value.platform,
        referenceSource: register.value.referenceSource,
        preferredLanguage: preferredLanguage.value,
      },
      register.value.deviceId
    )
  }

  async function fetchPasswordValidationRules() {
    try {
      const rules = await passwordRules.exec()
      register.value.passwordValidationRules = rules.requirements
    } catch (ex) {
      const error = formatError(ex)
      logError(
        'Failed to get password validation rules ' + error.message,
        'store/register.fetchPasswordValidationRules'
      )
    }
  }

  function setEmailOTPCode(value: string) {
    if (value) {
      register.value.emailOTPCode = value
    }
  }

  function resetRegister() {
    register.value.email = ''
    register.value.password = ''
    register.value.tcVersion = ''
  }

  async function init() {
    await fetchPasswordValidationRules()
  }

  async function resendConfirmationCodeEmail() {
    try {
      await resendConfirmationCode.exec(
        {
          username: register.value.email,
          location: useNavStore().currentFlow,
          platform: register.value.platform,
          preferredLanguage: preferredLanguage.value,
        },
        register.value.browserId
      )
    } catch (e) {
      const error = formatError(e)
      logError(error.message, 'store/register.resendConfirmationCode')
    }
  }

  async function resendChangeEmailCode() {
    try {
      // We're relying on the session_id cookie to get sent here, email is only for logging.
      await resendChangeEmailCodeAPI.exec(
        {
          username: register.value.email,
        },
        register.value.browserId
      )
    } catch (e) {
      const error = formatError(e)
      logError(error.message, 'store/register.resendChangeEmailCode')
    }
  }

  function signupEmail(hCaptcha?: string) {
    const result = signup.exec(
      {
        username: register.value.email,
        password: register.value.password,
        country: 'GB', // required by Launchpad, user will specify in registration
        tcVersion: register.value.tcVersion,
        referenceSource: register.value.referenceSource,
        hcaptcha: hCaptcha,
        devicePlatform: 'web',
        platform: register.value.platform,
        preferredLanguage: preferredLanguage.value,
      },
      register.value.deviceId
    )
    return result
  }

  async function signinEmail(hcaptchaValue?: string) {
    validatePlatformAndRedirect()
    const result = await signin.exec({
      username: register.value.email,
      password: register.value.password,
      devicePlatform: 'web',
      hcaptcha: hcaptchaValue,
      platform: register.value.platform,
      redirectUrl: register.value.redirectUrl,
      referenceSource: register.value.referenceSource,
      codeChallenge: register.value.oAuth.codeChallenge,
      codeChallengeMethod: register.value.oAuth.codeChallengeMethod,
      preferredLanguage: preferredLanguage.value,
      helpcentre: register.value.helpcentre,
    })
    setRedirectUrlWithAuthValues(result.authCode)
    return result
  }

  async function signinWith2FA(
    channel?: TwoFactorAuthChannel,
    phoneNumber?: string,
    otpCode?: string,
    rememberBrowser?: boolean,
    changeTwoFANumber?: boolean,
    profileId?: number
  ) {
    validatePlatformAndRedirect()

    const result = await signin.exec({
      username: register.value.email,
      password: register.value.password,
      devicePlatform: 'web',
      platform: register.value.platform,
      redirectUrl: register.value.redirectUrl,
      referenceSource: register.value.referenceSource,
      // codeChallenge: register.value.oAuth.codeChallenge,
      // codeChallengeMethod: register.value.oAuth.codeChallengeMethod,
      preferredLanguage: preferredLanguage.value,
      helpcentre: register.value.helpcentre,
      twoFANumber: phoneNumber,
      changeTwoFANumber: changeTwoFANumber,
      channel: channel,
      verifyCode: otpCode,
      rememberBrowser: rememberBrowser,
      twoFAProfileId: profileId,
      location: useNavStore().currentFlow,
    })
    setRedirectUrlWithAuthValues(result.authCode)
    return result
  }

  async function confirmEmailSignup() {
    validatePlatformAndRedirect()
    const result = await confirmSignup.exec({
      username: register.value.email,
      password: register.value.password,
      code: register.value.emailOTPCode,
      deviceId: register.value.deviceId,
      deviceToken: register.value.deviceId,
      devicePlatform: 'web',
      referenceSource: register.value.referenceSource,
      platform: register.value.platform,
      redirectUrl: register.value.redirectUrl,
      location: useNavStore().currentFlow,
      codeChallenge: register.value.oAuth.codeChallenge,
      codeChallengeMethod: register.value.oAuth.codeChallengeMethod,
      preferredLanguage: preferredLanguage.value,
    })
    setRedirectUrlWithAuthValues(result.authCode)
    return result
  }

  async function confirmEmailChanged() {
    return await confirmEmailChangedAPI.exec({
      code: register.value.emailOTPCode,
      referenceSource: register.value.referenceSource,
      platform: register.value.platform,
    })
  }

  function setTermsAndConditionsVersion(value: string) {
    register.value.tcVersion = value
  }

  async function confirmResetpassword() {
    validatePlatformAndRedirect()
    const result = await confirmForgotPassword.exec({
      username: register.value.email,
      password: register.value.password,
      code: register.value.emailOTPCode,
      referenceSource: register.value.referenceSource,
      devicePlatform: 'web',
      platform: register.value.platform,
      deviceId: register.value.deviceId,
      deviceToken: register.value.deviceId,
      preferredLanguage: preferredLanguage.value,
      redirectUrl: register.value.redirectUrl,
      codeChallenge: register.value.oAuth.codeChallenge,
      codeChallengeMethod: register.value.oAuth.codeChallengeMethod,
    })
    setRedirectUrlWithAuthValues(result.authCode)
    return result
  }

  function setRedirectUrl(value: string | null) {
    register.value.redirectUrl = value
  }

  function setRedirectQueryParams(value: string) {
    register.value.redirectQueryParams = value
  }

  function setPlatform(value: string) {
    if (value) {
      register.value.platform = value
    }
  }
  function setRateLimit(value: boolean) {
    if (value) {
      register.value.emailOTPCode = ''
      register.value.invalidCode = false
      register.value.expiredCode = false
      register.value.tooManyException = false
      register.value.rateLimit = value
    }
  }
  function setUserLocale(value: string) {
    if (value) {
      register.value.userLocale = value
    }
  }
  function setTooManyException(value: boolean) {
    if (value) {
      register.value.emailOTPCode = ''
      register.value.invalidCode = false
      register.value.expiredCode = false
      register.value.rateLimit = false
      register.value.tooManyException = value
    }
  }
  function setInvalidCode(value: boolean) {
    if (value) {
      register.value.expiredCode = false
      register.value.rateLimit = false
      register.value.tooManyException = false
      register.value.invalidCode = value
    }
  }
  function setExpiredCode(value: boolean) {
    if (value) {
      register.value.invalidCode = false
      register.value.rateLimit = false
      register.value.tooManyException = false
      register.value.expiredCode = value
    }
  }

  //Set referenceSource via redirect URL or from account selection
  function setReferenceSourceByUrl(redirectUrl: string) {
    if (redirectUrl) {
      const referenceSource = useEnvStore().getReferenceSourceFromRedirectURL(redirectUrl)
      register.value.referenceSource = referenceSource
    }
  }

  function setReferenceSource(referenceSource: ReferenceSourceType) {
    register.value.referenceSource = referenceSource
  }

  function setProfileId(id: number | undefined) {
    if (id) {
      register.value.profileId = id
    }
  }

  async function getGSOTokens(profileId?: number) {
    let gsoResponse
    try {
      gsoResponse = await getGSOTokensApi.exec(profileId)
    } catch (e) {
      // TODO endpoint will be updated to return a better error in case of 2FA
      if (e instanceof AxiosError && e.response?.data?.error.endsWith('has not completed 2FA')) {
        useTwoFactorAuthStore().setRequired(true)
        return
      }
      register.value.tokens = null
      register.value.oAuth.code = ''
      throw e
    }

    register.value.tokens = gsoResponse.tokens

    if (gsoResponse.auth_code) {
      register.value.oAuth.code = gsoResponse.auth_code
      setRedirectUrlWithAuthValues(gsoResponse.auth_code)
    }

    // decode token and check 2FA status
    if (gsoResponse.tokens.access_token) {
      const accessToken = jwtDecode<AccessToken>(gsoResponse.tokens.access_token)
      useTwoFactorAuthStore().setRequired(accessToken.require_2FA && !accessToken.completed_2FA)
    }

    return gsoResponse
  }

  async function getUserInfo() {
    return await userInfoApi.exec()
  }

  async function logout() {
    const requests = []
    // FXWeb logout
    if (platform.value === platforms.XEMT || platform.value === platforms.BRITLINE) {
      requests.push(
        fetch(useEnvStore().getEnvVariable('VUE_APP_URL_TRANSFER') + '/account/logout', {
          method: 'GET',
          credentials: 'include',
          mode: 'no-cors',
        })
      )
    }
    requests.push(logoutApi.exec())
    await Promise.all(requests)
  }

  function setOAuthState(state: string | null) {
    if (state) {
      register.value.oAuth.state = state
    }
  }
  function setOAuthStateCodeChallenge(codeChallenge: string | null) {
    if (codeChallenge) {
      register.value.oAuth.codeChallenge = codeChallenge
    }
  }
  function setOAuthStateCodeChallengeMethod(codeChallengeMethod: string | null) {
    if (codeChallengeMethod) {
      register.value.oAuth.codeChallengeMethod = codeChallengeMethod
    }
  }

  //Append oAuth state and code to  redirectQueryParams
  function setRedirectUrlWithAuthValues(authCode: string | undefined) {
    if (authCode) {
      let redirectQueryParams = ''
      const oAuthState = register.value.oAuth.state

      if (register.value.redirectQueryParams) {
        // we might have previous oauth query params - remove them
        redirectQueryParams = register.value.redirectQueryParams
        if (redirectQueryParams.startsWith('code=')) {
          redirectQueryParams = ''
        } else {
          redirectQueryParams = redirectQueryParams.split('&code=')[0]
        }
      }

      if (redirectQueryParams.length) {
        redirectQueryParams = `${redirectQueryParams}&code=${authCode}&state=${oAuthState}`
      } else {
        redirectQueryParams = `code=${authCode}&state=${oAuthState}`
      }
      setRedirectQueryParams(redirectQueryParams)
    }
  }

  /**
   * MT-37608: The guards should set redirectUrl and platform on initial page load.
   * Rarely these values are not being set, causing login errors.
   * In this case, default values for login to FXWeb
   */
  function validatePlatformAndRedirect() {
    if (!register.value.platform) {
      const platform = getPlatformByBrand(useBrandStore().brandName)
      // eslint-disable-next-line no-console
      console.error(`Platform missing, defaulted to ${platform}`)
      register.value.platform = platform
    }
    if (!register.value.redirectUrl) {
      const redirectUri = useEnvStore().getEnvVariable('VUE_APP_URL_TRANSFER')
      // eslint-disable-next-line no-console
      console.error(`RedirectUrl missing, defaulted to ${redirectUri}`)
      register.value.redirectUrl = redirectUri
    }
  }

  function setLinkedAccounts(linkedAccounts: ILinkedAccount[]) {
    register.value.linkedAccounts = linkedAccounts
  }

  return {
    email,
    password,
    passwordValidationRules,
    accountType,
    redirectUrl,
    redirectQueryParams,
    redirectUrlWithParams,
    platform,
    rateLimit,
    tooManyException,
    invalidCode,
    expiredCode,
    referenceSource,
    segmentVersionType,
    profileId,
    emailOTPCode,
    tokens,
    preferredLanguage,
    authCode,
    userLocale,
    deviceId,
    helpcentre,
    linkedAccounts,
    segmentAccountType,
    setTermsAndConditionsVersion,
    fetchPasswordValidationRules,
    setEmail,
    setEmailOTPCode,
    setPassword,
    resetRegister,
    init,
    resendConfirmationCodeEmail,
    confirmEmailSignup,
    signupEmail,
    signinEmail,
    signinWith2FA,
    resendChangeEmailCode,
    confirmEmailChanged,
    setAccountType,
    resetPassword,
    confirmResetpassword,
    setRedirectUrl,
    setRedirectQueryParams,
    setPlatform,
    setRateLimit,
    setTooManyException,
    setInvalidCode,
    setExpiredCode,
    setReferenceSourceByUrl,
    setReferenceSource,
    setProfileId,
    setUserLocale,
    getGSOTokens,
    logout,
    setOAuthState,
    setOAuthStateCodeChallenge,
    setOAuthStateCodeChallengeMethod,
    getUserInfo,
    setHelpcentre,
    setLinkedAccounts,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRegisterStore, import.meta.hot))
}
