import type { Locale } from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import { initLocale } from '~/utils/localiseHelper'
// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
const i18n = createI18n({
  legacy: false,
  locale: 'en-GB',
  fallbackLocale: ['en-GB', 'custom-2FA'],
  messages: {},
})

export async function setI18n() {
  const locale = await loadLanguageAsync()
  return { userLocale: locale, i18n }
}

function setI18nLanguage(lang: Locale) {
  i18n.global.locale.value = lang
  if (typeof document !== 'undefined') {
    document.querySelector('html')?.setAttribute('lang', lang)
  }
  return lang
}

async function loadLanguageAsync(): Promise<Locale> {
  const { locale, localePath } = initLocale()
  if (i18n.global.availableLocales.includes(locale)) {
    return setI18nLanguage(locale)
  }
  const messages = await localePath()

  i18n.global.setLocaleMessage(locale, messages?.default)

  // Need to add copy that isn't finalised and ready for translation?
  // Create a custom locale json file and reference it here

  // const twoFactorAuthLocale = Object.fromEntries(
  //   Object.entries(import.meta.glob('../locales/custom_example.json')).map(([, loadLocale]) => [
  //     'custom-example',
  //     loadLocale,
  //   ])
  // )
  // const twoFactorAuthMessages = await twoFactorAuthLocale['custom-example']()

  // i18n.global.setLocaleMessage('custom-example', twoFactorAuthMessages?.default)

  return setI18nLanguage(locale)
}
export const i18nGlobal = i18n.global
