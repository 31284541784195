import { acceptHMRUpdate, defineStore } from 'pinia'
import type { UserType } from '~/types'
import { navPaths } from '~/constants/navPaths'
import { i18nGlobal } from '~/modules/i18n'

export const useNavStore = defineStore('nav', () => {
  const route = useRoute()
  const { SIGNIN: signIn, SIGNUP: signUp, FORGOT_PASSWORD: forgotPassword, TWOFA } = navPaths

  const t = i18nGlobal.t
  const personalSteps = [
    {
      id: 0,
      title: t('ComponentRiaSidebar.ButtonAccount'),
      path: '/signup',
      pageTitle: t('createAccountPage.title'),
    },
    {
      id: 1,
      title: t('PageSendMoney.StepVerificationPageTitle'),
      path: '/verification',
      pageTitle: t('PageSendMoney.StepVerificationTitle'),
    },
    {
      id: 2,
      title: t('PageAccount.AccountSectionCountryLabel'),
      path: '/',
      pageTitle: t('ComponentCountryOfResidence.Title'),
    },
    {
      id: 3,
      title: t('PageRegister.StepInfoTitle'),
      path: '/',
      pageTitle: '',
    },
  ]
  const businessSteps = [
    {
      id: 0,
      title: t('ComponentRiaSidebar.ButtonAccount'),
      path: '/signup',
      pageTitle: t('createAccountPage.title'),
    },
    {
      id: 1,
      title: t('PageSendMoney.StepVerificationPageTitle'),
      path: '/verification',
      pageTitle: t('PageSendMoney.StepVerificationTitle'),
    },
    {
      id: 2,
      title: t('createAccountPage.business'),
      path: '/',
      pageTitle: t('createAccountPage.businessPageTitle'),
    },
    {
      id: 3,
      title: t('createAccountPage.personal'),
      path: '/',
      pageTitle: t('createAccountPage.personalPageTitle'),
    },
  ]
  type NavBarSteps = {
    activeStepId: number
    steps: {
      id: number
      title: string
      pageTitle: string
      path: string
    }[]
    withoutStepperPageTile: string
  }
  const navBarSteps = ref<NavBarSteps>({
    activeStepId: 0,
    steps: personalSteps,
    withoutStepperPageTile: '',
  })

  const redirectedFrom = ref('') //Strictly used for tracking from where the user were redirected to current page
  const showStepper = ref(true) //Display stepper in default layout. Layouts are selected at build time, but some pages may need to show/hide stepper at run time
  const previousFlow = ref('')

  const withoutStepperPageTile = computed(() => navBarSteps.value.withoutStepperPageTile)
  const activeStepId = computed(() => navBarSteps.value.activeStepId)
  const steps = computed(() => navBarSteps.value.steps)
  const activeStep = computed(() => steps.value.filter((step) => step.id === activeStepId.value)[0])

  /**
   * Determine the major flows depending on the current route or using redirectedFrom value
   * If user came from personal reg, it would be Signup
   * previousFlow holds the mainFlow; 'Sign up', 'Sign in', 'Forgot password'
   */
  const currentFlow = computed(() => {
    const mainPaths = [signIn, signUp, forgotPassword]
    const mainFlow = (path: string) => mapPathToFlow(filterNavPath(path, mainPaths))
    //If redirectedFrom is undefined and route is 2FA, it means user came from personal reg
    const is2FAPath = () => route.name?.includes(TWOFA) && !redirectedFrom.value
    if (is2FAPath()) {
      previousFlow.value = 'Sign up'
      return 'Sign up'
    }
    //If not landed or redirected from on mainFlow, then previous mainFlow to be used
    const currentFlow = mainFlow(route.name) ?? mainFlow(redirectedFrom.value) ?? previousFlow.value
    previousFlow.value = currentFlow ?? ''
    return currentFlow
  })

  function setWithoutStepperPageTitle(value: string) {
    navBarSteps.value.withoutStepperPageTile = value
  }
  function setActiveStepId(id: number) {
    const idExist = steps.value.find((step) => step.id === id)
    if (idExist?.id !== undefined) {
      navBarSteps.value.activeStepId = id
    }
  }

  function getActiveStepBypath(path: string) {
    return steps.value.find((step) => step.path === path)
  }

  function setActiveStepByPath(path: string) {
    const step = getActiveStepBypath(path)
    if (step) {
      setActiveStepId(step.id)
    }
  }
  function setRedirectedFrom(path: string) {
    if (path) {
      redirectedFrom.value = path
    }
  }
  function setSteps(step: UserType) {
    if (step === 'Business') {
      navBarSteps.value.steps = businessSteps
    } else {
      navBarSteps.value.steps = personalSteps
    }
  }

  function setShowStepper(show: boolean) {
    showStepper.value = show
  }

  return {
    activeStepId,
    steps,
    setActiveStepId,
    activeStep,
    setActiveStepByPath,
    setWithoutStepperPageTitle,
    withoutStepperPageTile,
    redirectedFrom,
    showStepper,
    setRedirectedFrom,
    setSteps,
    setShowStepper,
    currentFlow,
  }
})

const mapPathToFlow = (path: (typeof navPaths)['SIGNUP' | 'SIGNIN' | 'FORGOT_PASSWORD']) => {
  switch (path) {
    case navPaths.SIGNIN:
      return 'Sign in'
    case navPaths.SIGNUP:
      return 'Sign up'
    case navPaths.FORGOT_PASSWORD:
      return 'Forgot password'
    default:
      break
  }
}
const filterNavPath = (
  routeName: string,
  pathLists: (typeof navPaths)['SIGNUP' | 'SIGNIN' | 'FORGOT_PASSWORD'][]
) => {
  return pathLists.filter((path) => routeName?.includes(path))[0]
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot))
}
