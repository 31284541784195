export const platforms = {
  XEMT: 'XEMT',
  XECI: 'XECI',
  XERA: 'XERA',
  XECD: 'XECD',
  BRITLINE: 'BRITLINE',
  JOHN_LEWIS: 'JOHNLEWIS',
  ERP: 'ERP',
  DEVPORTAL: 'DEVPORTAL',
} as const
