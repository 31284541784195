import type { BrandName } from '~/types'
import {
  brands,
  brandHostNames,
  brandDisplayNames,
  brandSessionCookieNames,
} from '~/constants/brands'
import { platforms } from '~/constants/platforms'

type Config = {
  [x: string]: string
}
export function getBrandName(config: Config): BrandName {
  const hostName = window.location.hostname

  if (hostName.includes(brandHostNames.britline)) {
    return brands.britline
  } else if (hostName.includes(brandHostNames.johnlewis)) {
    return brands.johnlewis
  } else if (hostName.includes(brandHostNames.xe)) {
    return brands.xe
  } else {
    const brand = config['APP_BRAND'] ?? brands.xe
    return brand as BrandName
  }
}

export function getBrandDisplayName(brand: BrandName) {
  switch (brand) {
    case brands.xe:
      return brandDisplayNames.xe
    case brands.britline:
      return brandDisplayNames.britline
    case brands.johnlewis:
      return brandDisplayNames.johnlewis
  }
}

export function getBrandHostName(brand: BrandName) {
  switch (brand) {
    case brands.xe:
      return brandHostNames.xe
    case brands.britline:
      return brandHostNames.britline
    case brands.johnlewis:
      return brandHostNames.johnlewis
  }
}

export function getBrandSessionCookieName(brand: BrandName) {
  switch (brand) {
    case brands.xe:
      return brandSessionCookieNames.xe
    case brands.britline:
      return brandSessionCookieNames.britline
    case brands.johnlewis:
      return brandSessionCookieNames.johnlewis
  }
}

export function getPlatformByBrand(brand: BrandName) {
  if (brand === brands.britline) {
    return platforms.BRITLINE
  } else if (brand === brands.johnlewis) {
    return platforms.JOHN_LEWIS
  } else {
    return platforms.XEMT
  }
}
